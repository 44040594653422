"use strict";

var app = {
  mobileMenu: function mobileMenu() {
    var mobileBtn = $("#mobile-burger-btn");
    var pageBody = $("#page-wrap-inner");

    if (mobileBtn.length > 0) {
      // Load Menu
      mobileBtn.click(function () {
        if (mobileBtn.hasClass("menu-open") == true) {
          pageBody.removeClass("push");
          mobileBtn.removeClass("menu-open");
        } else {
          pageBody.addClass("push");
          mobileBtn.addClass("menu-open");
        }
      });
    }
  },
  validateEmail: function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  init: function init() {
    app.mobileMenu();

    $("#js-home-slider").slick({
      appendDots: "#pagingslider",
      dots: true,
      infinite: true,
      speed: 300,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      cssEase: "linear",
      autoplay: true,
      autoplaySpeed: 5000
    });
  }
};

$(document).ready(function () {
  app.init();
});